import React from "react";
import {Container, Row, Col, Image} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import {Title, Section, Text} from "../../components/Core";
import adslSVG from "../../assets/image/svg/adsl.svg"
import fttcSVG from "../../assets/image/svg/fttc.svg"
import fttpSVG from "../../assets/image/svg/fttp.svg"
import {sanitizeTextForLineBreaks} from "../../utils/helperFn";

const ConnectivityConfusion = () => {

    const FeatureTitle = styled(Text)`
      font-size: 1.3em;
      color:black;
      font-weight: bold;
    `;

    const FeatureContent = styled(Text)`
      line-height: 1.75;
      font-size: 1.2em;
    `;

    const data = useStaticQuery(graphql`
    query ConnectivityConfusionQuery {
      strapiConfusionSection {
        feature_1_content
        feature_1_title
        feature_2_content
        feature_2_title
        feature_3_content
        feature_3_title
        title
        subtitle
      }
    }`)

    const pageElements = data.strapiConfusionSection

    return (
        <Section bg="white" className="position-relative">
            <Container>

                <Row className="align-items-center">
                    <Col sm={12} className="text-center">
                        <Title>{pageElements.title}</Title>
                        <Text opacity={0.7} mb={4}>
                            {pageElements.subtitle}
                        </Text>
                    </Col>
                </Row>

                <Row className="align-items-center">

                    <Col sm={12} md={10} className="mx-auto mt-1 mt-md-5">
                        <Row>

                            {/* FTTP */}
                            <Col sm={12} className="mt-5 mb-5 pb-4 mb-md-0">
                                <Row>
                                    <Col sm={12} md={7}>
                                        <FeatureTitle className="mb-3 text-center">
                                            The Good
                                        </FeatureTitle>
                                        <Image alt="" src={fttpSVG} width={524} height={174} style={{ maxWidth:"100%" }}/>
                                    </Col>
                                    <Col sm={12} md={5} className="pl-5 mt-4 mt-md-0 connectivity-confusion-details">
                                        <FeatureTitle className="mb-4 text-center">
                                            {pageElements.feature_3_title}
                                        </FeatureTitle>
                                        <FeatureContent className="text-justify">
                                            {sanitizeTextForLineBreaks(pageElements.feature_3_content)}
                                        </FeatureContent>
                                    </Col>
                                </Row>
                            </Col>

                            {/* FTTC */}
                            <Col sm={12} className="mt-5 mb-5 pb-4 mb-md-0">
                                <Row>
                                    <Col sm={12} md={7} className="my-auto">
                                        <FeatureTitle className="mb-3 text-center">
                                            The Bad
                                        </FeatureTitle>
                                        <Image alt="" src={fttcSVG} width={524} height={174} style={{ maxWidth:"100%" }}/>
                                    </Col>
                                    <Col sm={12} md={5} className="pl-5 mt-4 mt-md-0 connectivity-confusion-details">
                                        <FeatureTitle className="mb-4 text-center">
                                            {pageElements.feature_2_title}
                                        </FeatureTitle>
                                        <FeatureContent className="text-justify" >
                                            {pageElements.feature_2_content}
                                        </FeatureContent>
                                    </Col>
                                </Row>
                            </Col>

                            {/* ADSL */}
                            <Col sm={12} className="mt-5 mb-5 mb-md-0">
                                <Row>
                                    <Col sm={12} md={7} className="my-auto">
                                        <FeatureTitle className="mb-3 text-center">
                                            The Ugly
                                        </FeatureTitle>
                                        <Image alt="" src={adslSVG} width={524} height={174} style={{ maxWidth:"100%" }}/>
                                    </Col>
                                    <Col sm={12} md={5} className="pl-5 mt-4 mt-md-0 connectivity-confusion-details">
                                        <FeatureTitle className="mb-4 text-center">
                                            {pageElements.feature_1_title}
                                        </FeatureTitle>
                                        <FeatureContent className="text-justify" >
                                            {pageElements.feature_1_content}
                                        </FeatureContent>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}



export default ConnectivityConfusion;
