import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";

const FeatureCard = ({
    color = "primary",
    imgUrl,
    title,
    children,
    ...rest
}) => (
    <Box width={"100%"} bg="light" p="30px" borderRadius={10} {...rest}>
        <div className="d-flex justify-content-between align-items-start">
            <Box
                size={69}
                minWidth={69}
                minHeight={69}
                borderRadius="50%"
                color={color}
                fontSize="28px"
                className="d-flex justify-content-center align-items-center"
                mr="20px"
                css={`
                  background-color: ${({ theme, color }) =>
                        rgba(theme.colors[color], 0.1)};
                `}
            >
                <img alt="" src={imgUrl}  className="img-fluid" width={69} height={69}/>
            </Box>
            <div className="pl-2 pr-2">
                <Text
                    color="heading"
                    as="h3"
                    fontSize={4}
                    fontWeight={500}
                    letterSpacing={-0.75}
                    mb={2}
                >
                    {title}
                </Text>
                <Text fontSize={2} lineHeight={1.75} className="text-justify">
                    {children}
                </Text>
            </div>
        </div>
    </Box>
);

const WhyGoFullFibre = () => {

    const data = useStaticQuery(graphql`
        query strapiWGFQuery {
            strapiWhyGoFullFibreSection {
                title_line_1
                title_line_2
                subtitle_text
                card_title_1
                card_text_1
                card_title_2
                card_text_2
                card_title_3
                card_text_3
                benefit_1_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
                benefit_2_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
                benefit_3_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
            }
        }      
    `);

    const pageData = getProperty(data, 'strapiWhyGoFullFibreSection');

    return (
        <>
            <Section style={{ background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)' }}>
                <Container>
                    <Row className="align-items-center">
                        <Col
                            md="8"
                            lg="5"
                            className="offset-lg-1 pl-lg-5 order-lg-2"
                            data-aos="fade-left"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="500"
                        >
                            <Box>
                                <Title color="light">{pageData.title_line_1}<br />{pageData.title_line_2}</Title>
                                <Text color="light" className="text-justify" opacity={0.9}>{pageData.subtitle_text}</Text>
                            </Box>
                        </Col>
                        <Col md="8" lg="6" className="order-lg-1 mt-5 mt-lg-0">
                            <div>
                                <Row className="justify-content-center">
                                    <Col
                                        md="12"
                                        data-aos="fade-right"
                                        data-aos-duration="750"
                                        data-aos-once="true"
                                        data-aos-delay="50"
                                    >
                                        <FeatureCard
                                            color="primary"
                                            imgUrl={pageData.benefit_1_icon?.childImageSharp?.original.src}
                                            title={pageData.card_title_1}
                                        >
                                            {pageData.card_text_1}
                                    </FeatureCard>
                                    </Col>
                                    <Col
                                        md="12"
                                        className="mt-4"
                                        data-aos="fade-right"
                                        data-aos-duration="750"
                                        data-aos-once="true"
                                        data-aos-delay="50"
                                    >
                                        <FeatureCard
                                            color="primary"
                                            imgUrl={pageData.benefit_2_icon?.childImageSharp?.original.src}
                                            title={pageData.card_title_2}
                                        >
                                            {pageData.card_text_2}
                                    </FeatureCard>
                                    </Col>
                                    <Col
                                        md="12"
                                        className="mt-4"
                                        data-aos="fade-right"
                                        data-aos-duration="750"
                                        data-aos-once="true"
                                        data-aos-delay="50"
                                    >
                                        <FeatureCard
                                            color="primary"
                                            imgUrl={pageData.benefit_3_icon?.childImageSharp?.original.src}
                                            title={pageData.card_title_3}
                                        >
                                            {pageData.card_text_3}
                                    </FeatureCard>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default WhyGoFullFibre;
