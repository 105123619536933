import React from "react";
import {Container, Row, Col, Image} from "react-bootstrap";
import {Title, Section, Box, Text} from "../../components/Core";
import styled from "styled-components";
import netomniaWorkers from "../../assets/image/jpeg/netomnia-workers.jpg"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
  padding-left: 60px;
  padding-bottom: 40px;
  position: relative;
  
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 0;
    min-width: 40px;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.colors.primary};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
    
  }
`;

const Widget = ({num = 1, title = "", children = "", ...rest}) => {
    return (
        <WidgetStyled {...rest}>
            <Title style={{color:'white', fontSize:'2em', lineHeight:'1.1em'}} className="mb-2">
                {title}
            </Title>
            <Text variant="small" style={{color: 'white', fontSize: '1.2em', lineHeight:'1.6em'}}>{children}</Text>
        </WidgetStyled>
    );
};


const BuildingFeatures = () => {

    const data = useStaticQuery(graphql`
        query strapiHomepageFeaturesQuery {
            strapiHomepageFeaturesSection {
                features_image {
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                item_title_1
                item_text_1
                item_title_2
                item_text_2
                item_title_3
                item_text_3
            }
        }      
    `);

    const pageData = getProperty(data, 'strapiHomepageFeaturesSection');

    return (
        <>
            <Section style={{ background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)' }}>
                <Container>
                    <Row className="align-items-center">

                        {/* Image */}
                        <Col sm={12} md={7} className="my-auto">
                            <Image alt="" src={netomniaWorkers} className="img-fluid" style={{ borderRadius: '6px' }} width={634} height={558}/>
                        </Col>

                        {/* Build features */}
                        <Col sm={12} md={5}>
                            <Row>
                                <Col md="12" className="pl-3 pl-md-5">
                                    <div>
                                        <Row className="justify-content-center">
                                            <WidgetContainer mt={5}>
                                                <Col
                                                    md="12"
                                                    data-aos="fade-right"
                                                    data-aos-duration="750"
                                                    data-aos-once="true"
                                                    data-aos-delay="50"
                                                >
                                                    <Widget title={pageData.item_title_1} mb={3} className="text-left">
                                                        {pageData.item_text_1}
                                                </Widget>
                                                </Col>
                                                <Col
                                                    md="12"
                                                    data-aos="fade-right"
                                                    data-aos-duration="750"
                                                    data-aos-once="true"
                                                    data-aos-delay="50"
                                                >
                                                    <Widget title={pageData.item_title_2} mb={3} className="text-left">
                                                        {pageData.item_text_2}
                                                </Widget>
                                                </Col>

                                                <Col
                                                    md="12"
                                                    data-aos="fade-right"
                                                    data-aos-duration="750"
                                                    data-aos-once="true"
                                                    data-aos-delay="50"
                                                >
                                                    <Widget title={pageData.item_title_3} mb={3} className="text-left">
                                                        {pageData.item_text_3}
                                                </Widget>
                                                </Col>
                                            </WidgetContainer>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
}

export default BuildingFeatures;
