import React from "react";
import styled from "styled-components";
import {Container, Row, Col, Image} from "react-bootstrap";
import {Title, Section, Text, Button, Box} from "../../components/Core";
import {device} from "../../utils";
import yflogo from "../../assets/image/svg/yf-logo.svg"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";

const FormStyled = styled.div`
  @media ${device.sm} {
    padding: 25px;
  }
  padding: 25px;
  box-shadow: ${({theme}) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({theme}) => theme.colors.light};
  width: 100%;
`;


const NetomniaProviders = () => {

    const data = useStaticQuery(graphql`
        query strapiHomepageProvidersQuery {
            strapiHomepageProvidersSection {
                title
                subtitle
                card_title
                card_text
                button_text
            }
        }      
    `);

    const pageData = getProperty(data, 'strapiHomepageProvidersSection');

    return (
        <>
            {/* <!-- Team section --> */}
            <Section className="position-relative pt-5">
                <Container>

                    <Row className="justify-content-center">
                        <Col lg="11" className="text-center pb-3">
                            <div className="pt-5 mt-5">
                                <Title>{pageData.title}</Title>
                                <Text className="mt-5">
                                    {pageData.subtitle}
                                </Text>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center justify-content-center pb-5 mb-3 mt-5">
                        <Col sm={12} md={8} className="text-center mx-auto pt-4">
                            <Box>
                                <FormStyled>
                                    <Image alt="" src={yflogo} width={200} />
                                    <hr className="mt-4"/>
                                    <h4 className="mt-4 mb-3" style={{color: '#000'}}>{pageData.card_title}</h4>
                                    <h5 style={{fontWeight: 200}}>{pageData.card_text}</h5>
                                    <hr className="mt-4"/>
                                    <a href="https://www.youfibre.com/?utm_source=In+Build&utm_medium=Button&utm_campaign=Postcode+Check" target="_blank">
                                        <Button className="mt-2">
                                            {pageData.button_text}
                                        </Button>
                                    </a>
                                </FormStyled>
                            </Box>
                        </Col>
                    </Row>

                </Container>
            </Section>
        </>
    )
}

export default NetomniaProviders
