import React, { createRef, useContext, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import {
    Title,
    Section,
    Box,
    Text, Button,
} from "../../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";
import animation from "../../assets/animations/hero-main.json"
import lottie from "lottie-web"
import { navigate } from "gatsby";
import CheckAvailability from "../../sections/index/CheckAvailability";

/*import netomniaHero from "../../assets/image/svg/netomnia-hero.svg"*/ /* V1 */
/*import netomniaHero from "../../assets/image/svg/netomnia-hero-2.svg"   /!* V2 *!/*/

const Hero = () => {

    const gContext = useContext(GlobalContext);
    const animationContainer = createRef()


    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animation
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);

    const data = useStaticQuery(graphql`
        query strapiHomepageHeroSectionQuery {
            strapiHomepageHeroSection {
                title
                subtitle
                button_text
            }
        }
    `);

    const pageData = getProperty(data, 'strapiHomepageHeroSection');

    return (
        <>
            <Section bg="#ffffff" className="position-relative pt-5 mt-5">
                <div className="d-none d-md-block" style={{ paddingTop: "110px" }} />
                <Container>
                    <Row className="align-items-center position-relative">

                        <Col md="7" xl="4" className="order-1 order-md-0 mt-5 mt-md-0">
                            <div className="pt-0 pt-md-4 mobile-hero">
                                <Box>
                                    <Title className="text-left head-title" style={{ fontSize: '53px' }} dangerouslySetInnerHTML={{ __html: pageData.title.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></Title>
                                    <Box mb={4} className="text-left">
                                        <Text color="dark">
                                            {pageData.subtitle}
                                        </Text>
                                    </Box>
                                    <CheckAvailability
                                        focusInput={false}
                                        hideLogo={true}
                                        customTitle={""}
                                        customSubtitle={""}
                                    />
                                </Box>
                            </div>
                            <div
                                className="pt-0 pt-md-4 desktop-hero"
                                data-aos="fade-left"
                                data-aos-duration={750}
                                data-aos-delay="500"
                                data-aos-once="true"
                            >
                                <Box>
                                    <Title className="text-left head-title" style={{ fontSize: '53px' }} dangerouslySetInnerHTML={{ __html: pageData.title.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></Title>
                                    <Box mb={4} className="text-left">
                                        <Text color="dark">
                                            {pageData.subtitle}
                                        </Text>
                                    </Box>
                                    <Box className="text-left">
                                        <CheckAvailability
                                            focusInput={false}
                                            hideLogo={true}
                                            customTitle={""}
                                            customSubtitle={""}
                                        />
                                    </Box>
                                </Box>
                            </div>
                        </Col>

                        <Col
                            md="5"
                            xl="8"
                            className="order-0 order-md-1"
                            data-aos="fade-right"
                            data-aos-duration="750"
                            data-aos-delay="500"
                            data-aos-once="true"
                        >
                            {/*<Image alt="" src={netomniaHero} className="img-fluid"/>*/}
                            <div id="animation-container" ref={animationContainer} />
                        </Col>

                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default Hero;
